<template>
   <div class="row">
    <div class="col s12 m6 offset-m3 z-depth-6">
      <div class="card-panel flex-col flex-hcenter">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Card"
};
</script>

<style module lang="scss">

</style>
