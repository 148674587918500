<template>
  <Card>
    <div class="row center">
      <h5>Passwort zurücksetzen</h5>
      <label v-if="!completed">Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen</label>
    </div>

    <div v-if="completed" class="center">
      Eine E-Mail mit Hinweisen zum weiteren Vorgehen wurde an die angegebene Adresse gesendet.<br><br>
      Bitte folgen Sie den darin enthaltenen Anweisungen, um Ihr Passwort zurückzusetzen.
    </div>
    <form v-else @submit.prevent="resetPassword" class="w-100">
      <div class="row">
        <InputField required v-model="email" id="email" label="Email" type="email" icon="mail_outline" />
      </div>

      <div class="flex-row flex-center">
        <button :disabled="busy" type="submit" class="btn waves-effect waves-light">Passwort zurücksetzen</button>
      </div>
    </form>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import InputField from "@/components/InputField.vue";
import { actions, http, routes } from "@/constants.js";

export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      completed: false
    };
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    }
  },
  methods: {
    async resetPassword() {
      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.ACCOUNT.RESET_PASSWORD.INITIALIZE,
        method: http.POST,
        body: { email: this.email }
      });

      if (result !== null) this.completed = true;
    }
  },
  components: {
    Card,
    InputField
  }
};
</script>

<style module type="scss">

</style>
